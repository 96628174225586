<script setup>
const { $get } = useAxios();

const { data: newsProducts, pending: isLoading } = await useAsyncData(
  "get last products limit 6",
  () =>
    $get(
      `/api/v1/product?limit=4&filter=isPublished||$eq||true&sort=publishedDate,DESC`
    ),
  { server: false }
);
</script>

<template>
  <div v-if="!isLoading">
    <div v-if="newsProducts && newsProducts.length">
      <div
        class="container px-4 xl:px-0 font-lato font-semibold text-xl lg:text-4xl text-primary-900"
      >
        Новое
      </div>
      <ClientOnly>
        <p-slider-product :products="newsProducts"></p-slider-product>
      </ClientOnly>
    </div>
  </div>
  <div v-else class="container mb-4 px-4 xl:px-0">
    <p
      class="font-lato font-semibold pb-5 text-xl lg:text-4xl text-primary-900"
    >
      Новое
    </p>
    <div
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
    >
      <p-card-sceleton class="" />
      <p-card-sceleton class="hidden sm:block" />
      <p-card-sceleton class="hidden lg:block" />
      <p-card-sceleton class="hidden xl:block" />
    </div>
  </div>
</template>
